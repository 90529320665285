<template>
  <div>
    <v-progress-linear
      class="loader"
      indeterminate
      v-show="isLoading"
      color="loaderColor"
    />
    <div
      :class="`organizations-dictionary-container${
        isLoading ? ' is-loading' : ''
      }`"
    >
      <div class="header">
        <div class="dictionaries-header">
          <v-btn
            depressed
            rounded
            text
            router
            class="header-navigation-button"
            v-can:DICT_READ
            :to="{ name: 'categories' }"
          >
            {{ $t("menu.category") }}
          </v-btn>
          <v-btn
            class="header-navigation-button white--text"
            color="navigationPageButtons"
            rounded
            v-can:DICT_READ
          >
            {{ $t("menu.organizations") }}
          </v-btn>
          <v-btn
            depressed
            rounded
            text
            router
            v-can:DICT_READ
            class="header-navigation-button"
            :to="{ name: 'departments' }"
          >
            {{ $t("menu.departments") }}
          </v-btn>
          <v-btn
            depressed
            rounded
            text
            router
            v-can:DICT_READ
            class="header-navigation-button"
            :to="{ name: 'defaultTags' }"
          >
            {{ $t("menu.defaultTags") }}
          </v-btn>
        </div>
      </div>

      <div class="content">
        <div class="content-table primary-table">
          <DictionaryContentTableWithSelect
            enableSelect
            :title="$t('dictionary.cities')"
            :readOnly="!hasUserPermissionsToEdit"
            :dictionaryParam="{}"
            :dictionaryParamItems="cities"
            :selectedItem="selectedItem"
            @setEditMode="setEditMode"
            @closeEditMode="closeEditMode"
            @addNewItem="addNewItem"
            @deleteItem="deleteItem"
            @editItem="editItem"
            @deleteAllItems="deleteAllItems"
            @selectItem="selectItem"
          />
        </div>

        <div class="content-table secondary-table" v-if="selectedItem">
          <DictionaryContentTable
            :readOnly="!hasUserPermissionsToEdit"
            :title="sideNumberTableTitle"
            :dictionaryParam="{}"
            :dictionaryParamItems="sideNumbers"
            @addNewItem="addChildItem"
            @deleteItem="deleteChildItem"
            @editItem="editChildItem"
            @setEditMode="setEditChildItem"
            @closeEditMode="closeEditChildItem"
          />
        </div>

        <div class="no-selected-item" v-else>
          {{ $t("dictionary.noCitySelected") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DictionaryParamsService from "@/services/DictionaryParamsService";

import DictionaryContentTable from "@/components/Dictionary/DictionaryContentTable";
import DictionaryContentTableWithSelect from "@/components/Dictionary/DictionaryContentTableWithSelect";

export default {
  name: "OrganizationsDictionaryList",
  components: { DictionaryContentTable, DictionaryContentTableWithSelect },
  data() {
    return {
      isLoading: false,
      selectedItem: null,
      citiesDictionaryId: 25,
      sideNumberDictionaryId: 37,
      sideNumberTableTitle: "",
      cities: [],
      sideNumbers: [],
    };
  },
  mounted() {
    this.getCities();
  },
  computed: {
    ...mapGetters("settings", ["getUserRestrictions"]),
    hasUserPermissionsToEdit() {
      const userPermissions = this.getUserRestrictions
        ? this.getUserRestrictions.global
        : [];

      return userPermissions.some((item) => item.code === "DICT_WRITE");
    },
  },
  methods: {
    getCities(keepSelecting = false) {
      this.isLoading = true;

      if (!keepSelecting) {
        this.selectedItem = null;
      }

      DictionaryParamsService.GetDictionaryValues(this.citiesDictionaryId)
        .then((response) => {
          this.cities = [...response];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getNumbers() {
      this.isLoading = true;
      DictionaryParamsService.GetDictionaryValusByParentId(this.selectedItem)
        .then((response) => {
          this.sideNumbers = [...response];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setEditMode(item) {
      const newData = [];

      this.cities.forEach((city) => {
        if (city.dictParamValueId === item.dictParamValueId) {
          newData.push({ ...city, editMode: true });
        } else {
          newData.push({ ...city });
        }
      });

      this.cities = [...newData];
    },
    closeEditMode(item) {
      const newData = [];

      this.cities.forEach((city) => {
        if (city.dictParamValueId === item.dictParamValueId) {
          newData.push({ ...city, editMode: false });
        } else {
          newData.push({ ...city });
        }
      });

      this.cities = [...newData];
    },
    addNewItem(newItemName) {
      const newItem = {
        dictParamValueId: 0,
        dictValue: newItemName,
        dictParamId: this.citiesDictionaryId,
      };

      this.isLoading = true;

      DictionaryParamsService.Create(newItem)
        .then(() => {
          this.getCities();
        })
        .finally(() => (this.isLoading = false));
    },
    deleteItem() {
      const item = this.cities.find(
        (x) => x.dictParamValueId === this.selectedItem
      );

      if (item) {
        this.isLoading = true;
        DictionaryParamsService.Delete(item.dictParamValueId)
          .then(() => {
            this.getCities();
          })
          .finally(() => (this.isLoading = false));
      }
    },
    editItem(newValue, item) {
      const data = {
        ...item,
        dictValue: newValue,
      };

      this.isLoading = true;
      DictionaryParamsService.Update(data)
        .then(() => {
          this.getCities(true);
        })
        .finally(() => (this.isLoading = false));
    },
    deleteAllItems() {},
    selectItem(item) {
      this.selectedItem = item.dictParamValueId;
      this.cities = this.cities.map((item) => ({ ...item, editMode: false }));
    },
    addChildItem(newItemName) {
      const newItem = {
        dictParamValueId: 0,
        dictValue: newItemName,
        dictParamParentValueId: this.selectedItem,
        dictParamId: this.sideNumberDictionaryId,
      };

      this.isLoading = true;

      DictionaryParamsService.Create(newItem)
        .then(() => {
          this.getNumbers();
        })
        .finally(() => (this.isLoading = false));
    },
    deleteChildItem(itemId) {
      this.isLoading = true;

      DictionaryParamsService.Delete(itemId)
        .then(() => {
          this.getNumbers();
        })
        .finally(() => (this.isLoading = false));
    },
    setEditChildItem(itemEdit) {
      this.sideNumbers = this.sideNumbers.map((item) => {
        if (itemEdit.dictParamValueId === item.dictParamValueId) {
          return { ...item, editMode: true };
        } else {
          return { ...item };
        }
      });
    },
    closeEditChildItem(itemEdit) {
      this.sideNumbers = this.sideNumbers.map((item) => {
        if (itemEdit.dictParamValueId === item.dictParamValueId) {
          return { ...item, editMode: false };
        } else {
          return { ...item };
        }
      });
    },
    editChildItem(newValue, item) {
      const data = {
        ...item,
        dictValue: newValue,
      };

      this.isLoading = true;
      DictionaryParamsService.Update(data)
        .then(() => {
          this.getNumbers();
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    selectedItem(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        const item = this.cities.find((x) => x.dictParamValueId === newValue);

        this.getNumbers();
        this.sideNumberTableTitle = `${this.$t("dictionary.sideNumbers")} dla ${
          item.dictValue
        }`;
      }
    },
  },
};
</script>

<styles scoped lang="scss">
.loader {
  margin: -12px -12px 0 -12px;
}

.organizations-dictionary-container {
  padding: 24px 12px 12px 12px;

  &.is-loading {
    margin-top: 8px;
  }

  .dictionaries-header {
    .header-navigation-button {
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }

  .content {
    margin-top: 30px;
    display: flex;

    .dictionary-content-table {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }

    .content-table {
      flex: 0 0 50%;
      background-color: white;
      border-radius: 5px;
      border: 1px solid $border-line;
      padding: 20px;

      .dictionary-content-table {
        height: calc(100vh - 340px);

        .list-items {
          overflow: auto;
          height: 100%;
          max-height: 100%;
        }
      }

      @media only screen and (min-width: 801px) {
        height: calc(100vh - 200px);
        max-height: calc(100vh - 200px);
      }

      @media only screen and (max-width: 800px) {
        height: calc(100vh - 230px);
        max-height: calc(100vh - 230px);
      }

      &.primary-table {
        margin-right: 5px;

        .item-wrapper {
          cursor: pointer;
        }
      }

      &.secondary-table {
        margin-left: 5px;
      }
    }

    .no-selected-item {
      flex: 0 0 50%;
      margin-left: 5px;
      padding: 20px;
      text-align: center;
      height: 100%;
    }
  }
}
</styles>
