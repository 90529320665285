<template>
  <div :class="`dictionary-content-table${isMobile ? ' mobile' : ''}`">
    <div class="header">
      <div class="header-title">
        {{ dictionaryParamName }}
      </div>
      <div class="header-action">
        <div v-if="!readOnly">
          <v-btn icon text @click="setEditMode" :disabled="!selectedItem">
            <IconBox color="iconButton">stem-pen</IconBox>
          </v-btn>
          <v-btn icon text @click="deleteItem" :disabled="!selectedItem">
            <IconBox color="iconButton">stem-delete</IconBox>
          </v-btn>
        </div>
        <v-menu v-if="displayMenuOptions">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <IconBox color="iconButton">stem-headerActions</IconBox>
            </div>
          </template>
          <v-list>
            <v-list-item @click="exportToCSV">
              <v-list-item-avatar>
                <IconBox color="iconButton">stem-csvExport</IconBox>
              </v-list-item-avatar>
              <v-list-item-content xs-align-right>
                <v-list-item-title>
                  {{ $t("dictionary.exportCsv") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!readOnly" @click="importItems">
              <v-list-item-avatar>
                <IconBox color="iconButton">stem-csvImport</IconBox>
              </v-list-item-avatar>
              <input
                type="file"
                ref="fileField"
                accept=".csv"
                @change="onFileChoose"
                style="display: none"
              />
              <v-list-item-content xs-align-right>
                <v-list-item-title>
                  {{ $t("dictionary.importCsv") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!readOnly" @click="deleteAllItems">
              <v-list-item-avatar>
                <IconBox color="iconButton">stem-deleteAll</IconBox>
              </v-list-item-avatar>
              <v-list-item-content xs-align-right>
                <v-list-item-title>
                  {{ $t("common.deleteAll") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div class="new-item">
      <v-text-field
        :disabled="readOnly"
        v-model="newItemName"
        placeholder="Dodaj nowe miasto"
      />
      <v-btn icon text :disabled="!newItemName" @click="addNewItem">
        <IconBox color="iconButton">fa-check</IconBox>
      </v-btn>
      <v-btn icon text :disabled="!newItemName" @click="clearNewItemName">
        <IconBox color="iconButton">fa-times</IconBox>
      </v-btn>
    </div>

    <div class="list-items">
      <span v-if="dictionaryItems.length === 0" class="no-items-message">
        {{ $t("common.noData") }}
      </span>
      <div
        :class="`item-wrapper ${
          item.dictParamValueId === selectedItem ? 'selected' : ''
        }`"
        v-for="(item, index) in dictionaryItems"
        :key="index"
        @click="selectItem(item)"
      >
        <div v-if="item.editMode" class="item edit-mode">
          <v-text-field
            v-if="item.editMode"
            :value="item.dictValue"
            ref="textField"
          />
          <div class="edit-item-buttons">
            <v-btn icon text @click="editItem(item)">
              <IconBox color="iconButton">fa-check</IconBox>
            </v-btn>
            <v-btn icon text @click="closeEditMode(item)">
              <IconBox color="iconButton">fa-times</IconBox>
            </v-btn>
          </div>
        </div>

        <div v-if="!item.editMode" class="item">
          <span>{{ item.dictValue }}</span>
        </div>
      </div>
    </div>

    <ModalDialog
      v-model="isNewImportItemModalShow"
      :header="$t('dictionary.newValue')"
      :confirmText="$t('global.save')"
      @confirm="onSaveNewItems"
      @cancel="onCancel"
      :maxWidth="'500px'"
    >
      <v-container slot="content">
        <v-flex xs12>
          <v-data-table
            :headers="[{ value: 'name' }]"
            :items="
              newImportedItems.map((x) => {
                return { name: x };
              })
            "
            hideDefaultFooter
            hideDefaultHeader
          >
            <template v-slot:items="props">
              <td>{{ props.item.name }}</td>
            </template>
          </v-data-table>
        </v-flex>
      </v-container>
    </ModalDialog>

    <ModalWindow ref="confirmModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DictionaryParamsService from "@/services/DictionaryParamsService.js";
import { EmitError, EmitSuccess, EmitWarning } from "@/eventBus.js";
import {
  generateCsv,
  importFromCsv,
  downloadCsvFile,
} from "@/utils/FileManager.js";

import ModalWindow from "@/components/Shared/ModalWindow";
import ModalDialog from "../Shared/ModalDialog";

export default {
  name: "DictionaryContentTableWithSelect",
  components: { ModalWindow, ModalDialog },
  props: {
    readOnly: { type: Boolean, default: false },
    dictionaryParam: { type: Object, default: () => {} },
    dictionaryParamItems: { type: Array },
    enableSelect: { type: Boolean, default: false },
    selectedItem: { type: Number },
    title: { type: String, default: "" },
    displayMenuOptions: { type: Boolean, default: false },
  },
  data() {
    return {
      newItemName: null,
      dictionaryItems: this.dictionaryParamItems || [],
      newImportedItems: [],
      isConfirmModalShown: false,
      isNewImportItemModalShow: false,
    };
  },
  watch: {
    dictionaryParamItems(value) {
      this.dictionaryItems = value;
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    dictionaryParamName() {
      if (this.title) {
        return this.title;
      }

      return this.dictionaryParam.name;
    },
  },
  methods: {
    addNewItem() {
      this.$emit("addNewItem", this.newItemName, this.dictionaryParam.dictId);
      this.newItemName = null;
    },
    clearNewItemName() {
      this.newItemName = null;
    },
    setEditMode() {
      const item = this.dictionaryItems.find(
        (x) => x.dictParamValueId === this.selectedItem
      );

      if (item) {
        this.$emit("setEditMode", item);
      }
    },
    closeEditMode(item) {
      this.$emit("closeEditMode", item);
    },
    deleteItem(item) {
      // event.stopPropagation();
      this.$refs.confirmModal
        .open(
          this.$t("global.confirm").toUpperCase(),
          this.$t("global.deleteItemConfirm")
        )
        .then((result) => {
          if (result) {
            this.$emit("deleteItem", item.dictParamValueId);
          }
        });
    },
    editItem(item) {
      const newValue = this.$refs.textField[0].lazyValue;
      this.$emit("editItem", newValue, item);
    },
    selectItem(item) {
      if (this.enableSelect && !item.editMode) {
        this.$emit("selectItem", item);
      }
    },
    deleteAllItems() {
      if (this.dictionaryItems.some((item) => !item.canDelete)) {
        EmitWarning(this.$t("dictionary.deleteWarning"));
      } else {
        this.$refs.confirmModal
          .open(
            this.$t("global.confirm").toUpperCase(),
            this.$t("global.confirmText")
          )
          .then((result) => {
            if (result) {
              this.$emit("deleteAllItems", this.dictionaryParam.dictId);
            }
          });
      }
    },
    exportToCSV() {
      const items = this.dictionaryItems.map((x) => [x.dictValue]);
      const csv = generateCsv(false, items);
      downloadCsvFile(
        csv,
        `${this.dictionaryParamName}_${this.$moment().format("dateTime")}.csv`
      );
    },
    importItems() {
      this.$refs.fileField.click();
    },
    onFileChoose(event) {
      const files = event.currentTarget.files;
      importFromCsv(files[0], this.parseImportedObjects);
    },
    parseImportedObjects(data) {
      if (data[0][0].split(";").length > 1) {
        EmitWarning(this.$t("dictionary.tooManyColumns"));
        return;
      }

      this.$refs.fileField.value = null;
      if (data.length > 0) {
        this.newImportedItems = data.map((e) => e[0]);
        this.isNewImportItemModalShow = true;
      } else {
        EmitWarning(this.$t("dictionary.importError"));
      }
    },
    onSaveNewItems() {
      DictionaryParamsService.CreateFromCsv(
        this.dictionaryParam.dictId,
        this.newImportedItems,
        this.dictionaryParam.dictId
      )
        .then(() => {
          EmitSuccess(this.$t("dictionary.createAllSuccess"));
        })
        .catch(() => EmitError(this.$t("dictionary.createError")))
        .finally(() => (this.isNewImportItemModalShow = false));
    },
    onCancel() {
      this.$refs.confirmModal
        .open(
          this.$t("global.confirm").toUpperCase(),
          this.$t("common.closeConfirm")
        )
        .then((result) => {
          if (result) {
            this.isNewImportItemModalShow = false;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.dictionary-content-table {
  margin-right: 20px;
  width: 260px;
  min-width: 260px;
  max-width: 260px;

  &.mobile {
    margin: 0;
    width: 100%;
    max-width: unset;
    min-width: unset;

    .list-items {
      .item-wrapper {
        .item {
          .item-buttons {
            display: flex;
          }
        }
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    .header-title {
      color: $text-title;
    }

    .header-action {
      margin-left: auto;
      display: flex;
    }
  }

  .new-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .list-items {
    .no-items-message {
      display: block;
      text-align: center;
    }

    .item-wrapper {
      &.selected {
        background-color: #67b1e2 !important;
      }

      &:nth-child(odd) {
        background-color: $content-section-background;
      }

      .item {
        min-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 5px;

        &.edit-mode {
          height: 40px;

          .edit-item-buttons {
            display: flex;
          }
        }

        .item-buttons {
          margin-left: auto;
          display: none;
        }

        &:not(.edit-mode):hover {
          .item-buttons {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
